<div class="dashboard-tabs">
  <div class="dashboard-tabs-container" *ngFor="let tab of tabs">
    <div
      class="dashboard-tabs-container-label"
      [ngClass]="{ active: tab.key === key }"
      (click)="navigate(tab.route)"
    >
      <label>{{ tab.label }}</label>
    </div>
  </div>
</div>
