import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormBaseComponent } from 'src/app/shared/components/base/form-base.component';

@Component({
  selector: 'purpl-change-pw-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './change-pw-login.component.html',
  styleUrls: ['./change-pw-login.component.scss'],
})
export class ChangePwLoginComponent
  extends FormBaseComponent
  implements OnInit
{
  public isLoading = false;
  public formValid = false;
  public formSubmitted = false;
  public hideNew = false;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.addFormValidations();
  }

  onSubmit(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.formSubmitted = true;

    if (this.formGroup.status === this.constantList.VALID_FORM_STATE) {
      const values = this.formGroup.getRawValue();
      const body = {
        password: values.password,
      };

      this.dataSubscription$ = this.userService.updatePassword(body).subscribe(
        (user) => {
          if (user) {
            this.handleRedirection(true);
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  /**
   * The following method is used
   * @returns {string}
   * @param formElementName
   */
  getErrorMessage(formElementName: string): string[] {
    const formElement = this.formGroup.get(formElementName);
    const errors: any[] = [];
    // check if any error exists or not
    if (formElement.errors) {
      // detect the required validation being passed on the respective form control i.e. password in this case
      if (formElementName === 'password' && formElement.errors.required) {
        errors.push('Password is required');
        // return 'Password is required';
      }
      // detect the minLength validation being passed on the respective form control i.e. password in this case
      if (formElement.errors.minlength) {
        errors.push('Password must be 8 - 20 characters long');
        // return 'Password must be 8 - 20 characters long';
      }

      if (formElement.errors.hasUpper) {
        errors.push('At least 1 upper case');
        // return 'At least 1 upper case';
      }

      if (formElement.errors.hasLower) {
        errors.push('At least 1 lower case');
        // return 'At least 1 lower case';
      }

      if (formElement.errors.hasNumber) {
        errors.push('At least 1 number');
        // return 'At least 1 number';
      }

      if (formElement.errors.hasChars) {
        errors.push('At least 1 special character');
        // return 'At least 1 special character';
      }

      if (formElement.errors.hasWhiteSpace) {
        errors.push('Password cannot contain space(s)');
        // return 'At least 1 special character';
      }

      if (formElement.errors.hasAlphaNum) {
        errors.push(
          'Password cannot contain alphanumeric sequences like "1234" or "abcd"'
        );
        // return 'At least 1 special character';
      }
    }

    return errors;
  }

  addFormValidations(): void {
    const passwordValidations: ValidatorFn[] = [
      Validators.required,
      Validators.minLength(this.constantList.PASSWORD_MIN_LENGTH),
      this.PasswordHasUpperCase,
      this.PasswordHasLowerCase,
      this.PasswordHasNumber,
      this.PasswordHasSpecialChars,
      this.PasswordHasNoWhiteSpace,
      // this.PasswordHasAlphaNumeric,
    ];
    // adding validations to the login form
    this.addFormControlWithValidations('password', passwordValidations);
  }

  handleRedirection(reload: boolean = false): void {
    if (!reload) {
      this.sharedDataService.changeFormSubmitStatus(false);
    }
    reload
      ? window.location.replace(this.routeList.DASHBOARD)
      : this.goToWithoutConfirmation(this.routeList.DASHBOARD);
  }
}
