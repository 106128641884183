import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ENCRYPTION_SECRET } from '../constants/constant-list';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  private isValidJSON(jsonString: string) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  }

  // @ts-ignore
  setDataInLocalStorage({ key, value }) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getDataInLocalStorage(key: string) {
    const item = localStorage.getItem(key);
    if (item && item != undefined) {
      if (this.isValidJSON(item)) {
        return JSON.parse(item);
      } else {
        return item;
      }
    }
    return null;
  }

  removeDataInLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  remove(key: string): void {
    this.removeDataInLocalStorage(key);
  }

  get(key: string): any {
    return this.getDataInLocalStorage(key);
  }

  // @ts-ignore
  set({ key, value }): void {
    this.setDataInLocalStorage({ key, value });
  }

  clearDataInLocalStorage() {
    localStorage.clear();
  }

  getToken() {
    return this.getEncryptedData('purpl_token', ENCRYPTION_SECRET);
  }

  getRefreshToken() {
    return this.getEncryptedData('purpl_refresh_token', ENCRYPTION_SECRET);
  }

  getLocalStorageLength() {
    return localStorage.length;
  }

  storeSessionStorageInLocalStorage(): void {
    for (let i = 0; i < sessionStorage.length; i++) {
      this.setDataInLocalStorage({
        key: sessionStorage.key(i),
        // @ts-ignore
        value: JSON.parse(sessionStorage.getItem(sessionStorage.key(i))),
      });
    }
  }

  getEncryptedData(key: string, secret: any = null): any {
    try {
      const item = localStorage.getItem(key);
      if (item && item != 'undefined') {
        const bytes = CryptoJS.AES.decrypt(item, secret);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }

      return null;
    } catch {
      return null;
    }
  }

  // @ts-ignore
  setEncryptData({ key, value }, secret: any = null): void {
    localStorage.setItem(
      key,
      CryptoJS.AES.encrypt(JSON.stringify(value), secret).toString()
    );
  }
}
