import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';

export interface TransfersTransactionState {
  transfers: ApiResponseBodyInterface | null;
  loadingTransfers: boolean;
}

const initialState: TransfersTransactionState = {
  transfers: null,
  loadingTransfers: false,
};

const { actions, reducer, name } = createSlice({
  name: 'transfersTransaction',
  initialState,
  reducers: {
    getAllTransfersTransactionPending: (state) => {
      state.loadingTransfers = true;
    },
    getAllTransfersTransactionFullfilled: (state, { payload }) => {
      state.loadingTransfers = false;
      state.transfers = payload;
    },
    getAllTransfersTransactionRejected: (state, { payload }) => {
      state.loadingTransfers = true;
    },
  },
});

const selectTransfersTransactionFeature =
  createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectTransfersTransactionLoading = createSelector(
  selectTransfersTransactionFeature,
  (state) => state.loadingTransfers
);

export const selectAllTransfersTransactions = createSelector(
  selectTransfersTransactionFeature,
  (state) => state.transfers
);

export { name };
export const {
  getAllTransfersTransactionPending,
  getAllTransfersTransactionFullfilled,
  getAllTransfersTransactionRejected,
} = actions;
export default reducer;
